import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import requestIdleCallback from 'lib/requestIdleCallback'

import useUIContext from 'context/ui'

import { Container, Section } from 'components/layout'
import { Headline2 } from 'components/ui/Typography'
import VideoLightbox from 'components/ui/VideoLightbox'

import HeroTitle from './HeroTitle'

import s from './HeroSection.module.scss'
const cn = classNames.bind(s)

const HeroSection = ({ title, videoTrigger, videoEmbedID }) => {
  const [inView, setInView] = useState(false)

  const toggleHiddenHeader = useUIContext((s) => s.toggleHiddenHeader)
  const [isLightboxVisible, setIsLightboxVisible] = useState(false)
  const wrapperRef = useRef(null)
  const isPageTransitioning = useUIContext((s) => s.isPageTransitioning)
  const setCursorIcon = useUIContext((s) => s.setCursorIcon)

  // Lightbox state - SHOW
  const showLightbox = () => {
    setIsLightboxVisible(true)
    window.scrollTo(0, 0)

    requestIdleCallback(() => {
      toggleHiddenHeader(true)
      window.addEventListener('scroll', hideLightbox, { once: true })
    })
  }

  // Lightbox state - HIDE
  const hideLightbox = () => {
    setIsLightboxVisible(false)
    toggleHiddenHeader(false)
    setCursorIcon(null)
    window.removeEventListener('scroll', hideLightbox)
  }

  return (
    <Section className={cn('section')} fullHeight onEnter={() => setInView(true)}>
      <Container>
        <div className={cn('wrapper', { inView, isLightboxVisible, isPageTransitioning })} ref={wrapperRef}>
          <Headline2 className={cn('title')}>
            <HeroTitle
              title={title}
              videoTrigger={videoEmbedID && videoTrigger}
              onClick={videoEmbedID && showLightbox}
            />
          </Headline2>
        </div>
      </Container>
      {videoEmbedID && (
        <VideoLightbox embedID={videoEmbedID} isVisible={isLightboxVisible} onHide={hideLightbox} onTop />
      )}
    </Section>
  )
}

HeroSection.propTypes = {
  title: PropTypes.string.isRequired,
  videoTrigger: PropTypes.string,
  videoEmbedID: PropTypes.string,
}

export default HeroSection
