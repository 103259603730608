import React, { forwardRef, useRef, useEffect } from 'react'
import { useWindowSize } from '@react-hook/window-size'
import PropTypes from 'prop-types'

import { desktop } from 'styles/config/_breakpoints.scss'

import replaceQuote from 'lib/replaceQuote'
import AspectRatio from 'components/ui/AspectRatio'

const normalStyle = `style="display: block; width: 100%;"`
const invisibleStyle = `style="display: block; position: absolute; opacity: 0; top: 0; left: 0; width: 100%; height: 100%; z-index: -1; pointer-events: none;"`

const ConditionalAspectRatio = ({ ratio, children }) => {
  if (!isFinite(ratio)) return children
  return <AspectRatio ratio={ratio}>{children}</AspectRatio>
}

ConditionalAspectRatio.propTypes = {
  ratio: PropTypes.number,
}

/**
 * Renders a <video> tag using dangerouslySetInnerHTML to make sure `muted` attribute is respected.
 * Optional parameter to make element hidden using styles that don't affect playback on iOS (tested on iOS13)
 */
const Video = forwardRef(
  (
    {
      webm,
      mp4,
      invisible,
      poster,
      playsinline = true,
      muted = true,
      autoplay = true,
      loop = true,
      preload = 'auto',
      controls = false,
      skipForMobile = true,
      ratio,
      title,
      className,
      ...props
    },
    outerRef,
  ) => {
    const [viewportWidth] = useWindowSize(0)
    const isSSR = typeof window === 'undefined'
    const innerRef = useRef()
    const ref = outerRef || innerRef
    const webmRef = useRef()
    const mp4Ref = useRef()

    useEffect(() => {
      if ((skipForMobile && viewportWidth < parseInt(desktop)) || isSSR) return
      webmRef.current = webm
      mp4Ref.current = mp4
    }, [isSSR, viewportWidth])

    return (
      <ConditionalAspectRatio ratio={ratio}>
        <div
          className={className}
          ref={(r) => r && (ref.current = r.children[0])}
          {...props}
          dangerouslySetInnerHTML={{
            __html: `
          <video
            ${loop ? 'loop' : ''}
            ${muted ? 'muted' : ''}
            ${autoplay ? 'autoplay' : ''}
            ${controls ? 'controls' : ''}
            ${playsinline ? 'playsinline' : ''}
            crossOrigin="anonymous"
            ${preload ? `preload="${preload}"` : ''}
            ${invisible ? invisibleStyle : normalStyle}
            ${poster ? `poster=${poster}` : ''}
            ${title ? `title="${replaceQuote(title)}"` : ''}
          >
            ${webmRef.current ? `<source src="${webmRef.current}#t=0.0" type="video/webm; codecs=vp9,vorbis" />` : ''}
            ${mp4Ref.current ? `<source src="${mp4Ref.current}#t=0.0" type="video/mp4" />` : ''}
          </video>
        `,
          }}
        />
      </ConditionalAspectRatio>
    )
  },
)

Video.displayName = 'Video'

Video.propTypes = {
  className: PropTypes.string,
  webm: PropTypes.string,
  mp4: PropTypes.string,
  invisible: PropTypes.bool,
  playsinline: PropTypes.bool,
  muted: PropTypes.bool,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
  preload: PropTypes.string,
  poster: PropTypes.string,
  ratio: PropTypes.number,
  controls: PropTypes.bool,
  title: PropTypes.string,
  skipForMobile: PropTypes.bool,
}
export default Video
