import React, { useState, useEffect, useRef } from 'react'
import { useWindowSize } from '@react-hook/window-size'
import { TransitionState } from 'gatsby-plugin-transition-link'
import classNames from 'classnames/bind'

import ViewportEnter from 'components/motion/ViewportEnter'

import { CURSOR_ICONS } from 'components/ui/Cursor'
import CursorHover from 'components/ui/CursorHover'

import { Section } from 'components/layout'

import CategoriesContent from './CategoriesContent'
import Thumbnail from './Thumbnail'

import { desktop } from 'styles/config/_breakpoints.scss'

import s from './CategoriesSection.module.scss'
const cn = classNames.bind(s)

const Lines = () => (
  <div>
    {[...Array(5).keys()].map((i) => (
      <span key={i} className={cn('line')} />
    ))}
  </div>
)

const CategoriesSection = ({ data }) => {
  const content = data?.map((c) => ({ title: c.category.title, uid: c.category._meta.uid }))
  const media = data?.map((c) => ({
    portraitImage: c.category?.portrait_imageSharp,
    portraitImageAlt: c.category?.portrait_image?.alt,
    landscapeImage: c.category?.landscape_imageSharp,
    landscapeImageAlt: c.category?.landscape_image?.alt,
    webm: c.category?.landscape_video_webm?.url,
    mp4: c.category?.landscape_video_mp4?.url,
  }))

  const [viewportWidth] = useWindowSize({ initialWidth: 0, wait: 300 })
  const [isInView, setIsInView] = useState(false)
  const [isDraggable, setIsDraggable] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const element = useRef()

  useEffect(() => setIsDraggable(viewportWidth <= parseInt(desktop)), [viewportWidth])

  return (
    <Section>
      <TransitionState>
        {({ mount, transitionStatus, current } = {}) => {
          return (
            <ViewportEnter
              onEnter={() => setIsInView(true)}
              onExit={() => setIsInView(false)}
              threshold={0.6}
              once={false}
            >
              <div className={cn('wrapper')} ref={element}>
                <CursorHover icon={CURSOR_ICONS.VIDEO_CONTROLS_PLAY}>
                  <div className={cn('sizer', transitionStatus, { isInView })}>
                    <CategoriesContent
                      categories={content}
                      isDraggable={isDraggable}
                      onActive={setActiveIndex}
                      activeIndex={activeIndex}
                    />
                    <div className={cn('categoriesThumbnails')}>
                      {media.map((m, i) => (
                        <Thumbnail key={i} media={m} isActive={i === activeIndex} />
                      ))}
                    </div>
                    <Lines />
                  </div>
                </CursorHover>
              </div>
            </ViewportEnter>
          )
        }}
      </TransitionState>
    </Section>
  )
}

export default CategoriesSection
