import React, { useState } from 'react'
import { useInterval } from 'react-use'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { motion, AnimatePresence } from 'framer-motion'

import ViewportEnter from 'components/motion/ViewportEnter'
import Page from './Page'

import s from './PartnersSection.module.scss'
const cn = classNames.bind(s)

const _ = {
  delay: 4000,
}

const Carousel = ({ pages }) => {
  const [isInView, setIsInView] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)

  useInterval(() => isInView && setActiveIndex((activeIndex + 1) % pages.length), _.delay)

  return (
    <ViewportEnter onEnter={() => setIsInView(true)} onExit={() => setIsInView(false)} once={false}>
      <div className={cn('logos')}>
        <AnimatePresence exitBeforeEnter={true}>
          <motion.div
            key={activeIndex}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
          >
            <Page logos={pages[activeIndex]} />
          </motion.div>
        </AnimatePresence>
      </div>
    </ViewportEnter>
  )
}

Carousel.propTypes = {
  pages: PropTypes.array.isRequired,
}

export default Carousel
