import React, { useState, useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { play, pause } from 'lib/videoControls'

import { desktop } from 'styles/config/_breakpoints.scss'

import { CURSOR_ICONS } from 'components/ui/Cursor'
import CursorHover from 'components/ui/CursorHover'
import AspectRatio from 'components/ui/AspectRatio'
import Video from 'components/ui/Video'

import s from './WorkThumbnail.module.scss'
const cn = classNames.bind(s)

const WorkThumbnail = ({
  className,
  portraitImage,
  portraitVideoMP4,
  portraitVideoWEBM,
  landscapeImage,
  alt,
  view = {},
  delay = 0,
  showGradient = false,
}) => {
  const [isActive, setIsActive] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const wrapperRef = useRef()
  const element = useRef()
  const videoRef = useRef()
  const to = useMemo(() => {
    if (view.transitioningTo) {
      const str = view.transitioningTo.toLowerCase()
      return str === '/' ? `to_home_page` : `to_${view.transitioningTo.toLowerCase()}`
    }
    return ''
  }, [view])

  const hasImage = !!portraitImage || !!landscapeImage
  const hasVideo = !!portraitVideoMP4?.url || !!portraitVideoWEBM?.url
  const [desktopBreakpoint, setDesktopBreakpoint] = useState(null)

  useEffect(() => {
    if (!videoRef.current) return
    isActive ? play(videoRef.current) : pause(videoRef.current)
  }, [isActive, videoRef])

  useEffect(() => {
    if (!wrapperRef.current) return
    wrapperRef.current.style.setProperty('--delay', delay)
  }, [wrapperRef])

  useEffect(() => setDesktopBreakpoint(parseInt(desktop)), [desktop])

  return (
    <div
      className={cn('wrapper', view.inView && to, {
        inView: view.inView,
        animateIn: view.inView && view.transitionStatus.includes('enter'),
        animateOut: view.inView && view.transitionStatus === 'exiting',
        showGradient,
        isClicked,
        hasVideo,
      })}
      ref={wrapperRef}
      onPointerEnter={() => setIsActive(true)}
      onPointerLeave={() => setIsActive(false)}
      onClick={() => setIsClicked(true)}
    >
      <div className={cn('outer')}>
        <div className={cn('inner')}>
          <CursorHover icon={CURSOR_ICONS.VIDEO_CONTROLS_PLAY}>
            <AspectRatio ratio={2 / 1} desktopRatio={1 / 2}>
              <div className={cn('thumbnail', className)} ref={element}>
                {/* TODO: extract to a component based on OriginalThumbnail as well (°•°) */}
                <div className={cn('media')}>
                  {hasImage && (
                    <picture alt={alt || portraitImage?.alt || 'work thumbnail'}>
                      <source media={`(min-width: ${desktopBreakpoint}`} srcSet={portraitImage} />
                      <img alt={alt || landscapeImage.alt || 'work thumbnail'} src={landscapeImage} />
                    </picture>
                  )}
                  {hasVideo && (
                    <Video
                      className={cn('video')}
                      mp4={portraitVideoMP4?.url || null}
                      webm={portraitVideoWEBM?.url || null}
                      ref={videoRef}
                      autoplay={false}
                    />
                  )}
                </div>
              </div>
            </AspectRatio>
          </CursorHover>
        </div>
      </div>
    </div>
  )
}

WorkThumbnail.propTypes = {
  portraitImage: PropTypes.string,
  portraitVideoMP4: PropTypes.object,
  portraitVideoWEBM: PropTypes.object,
  landscapeImage: PropTypes.string,
  alt: PropTypes.string,
  view: PropTypes.object,
  delay: PropTypes.string,
  showGradient: PropTypes.bool,
}

export default WorkThumbnail
