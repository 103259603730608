import { graphql } from 'gatsby'

import { PrismicLinkFrag } from 'fragments/PrismicLink'

import HomePage from 'views/home/HomePage'

export const query = graphql`
  query HomePageQuery {
    prismic {
      page: home_page(uid: "home", lang: "en-us") {
        page_meta_title
        page_meta_description
        page_meta_thumbnail
        title
        hero_title
        hero_video_trigger
        hero_video_embed_id
        body {
          ... on PRISMIC_Home_pageBodyBlog_section {
            type
            primary {
              section_title
            }
            fields {
              blog_post {
                ...PrismicLinkFrag
                ... on PRISMIC_Blog_post {
                  title: blog_post_title
                  thumbnail_description
                  thumbnail
                  thumbnailSharp {
                    childImageSharp {
                      fluid(maxWidth: 2000) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PRISMIC_Home_pageBodyWorksection {
            type
            primary {
              section_title
            }
            fields {
              case_study {
                ...PrismicLinkFrag
                ... on PRISMIC_Project_page {
                  title
                  portrait_video_webm {
                    ... on PRISMIC__FileLink {
                      url
                    }
                  }
                  portrait_video_mp4 {
                    ... on PRISMIC__FileLink {
                      url
                    }
                  }
                  portrait_image
                  portrait_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                  landscape_image
                  landscape_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PRISMIC_Home_pageBodyCategories_section {
            type
            fields {
              category {
                ...PrismicLinkFrag
                ... on PRISMIC_Project_category {
                  _meta {
                    uid
                  }
                  title
                  portrait_image
                  portrait_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                  landscape_video_webm {
                    ... on PRISMIC__FileLink {
                      url
                    }
                  }
                  landscape_video_mp4 {
                    ... on PRISMIC__FileLink {
                      url
                    }
                  }
                  landscape_image
                  landscape_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 2000) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PRISMIC_Home_pageBodyInfo_section {
            type
            primary {
              title
              subtitle
              content
              link {
                ...PrismicExtLinkFrag
                ...PrismicDocLinkFrag
              }
              link_text
            }
          }
          ... on PRISMIC_Home_pageBodyInteractive_cards_section {
            type
            primary {
              section_title
              link {
                ...PrismicExtLinkFrag
                ...PrismicDocLinkFrag
              }
              link_text
            }
            fields {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          ... on PRISMIC_Home_pageBodyPartners_section {
            type
            primary {
              section_title
            }
            fields {
              logo
            }
          }
        }
      }
      allProject_pages {
        totalCount
      }
    }
  }
`

HomePage.query = query
HomePage.fragments = [PrismicLinkFrag]

export default HomePage
