import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import CloseIcon from 'assets/svg/menu-close.inline.svg'
import { CURSOR_ICONS } from 'components/ui/Cursor'
import CursorHover from 'components/ui/CursorHover'
import AspectRatio from 'components/ui/AspectRatio'
import WistiaEmbed from 'components/ui/WistiaEmbed'
import Portal from 'components/ui/Portal'

import s from './VideoLightbox.module.scss'
const cn = classNames.bind(s)

const VideoLightbox = ({ embedID, isVisible = true, className, onTop, onHide, ...props }, ref) => (
  <Portal root="lightbox">
    <div ref={ref} className={cn('wrapper', className, { isVisible, onTop })}>
      <CursorHover icon={CURSOR_ICONS.CLOSE}>
        <div className={cn('backdrop')} onClick={onHide} />
      </CursorHover>
      <CursorHover icon={CURSOR_ICONS.CLOSE}>
        <button className={cn('closeButton', { isVisible })} aria-label="close" onClick={onHide}>
          <CloseIcon />
        </button>
      </CursorHover>
      <div className={cn('frame')}>
        <AspectRatio ratio={16 / 9}>
          {/* Needs to be in the DOM for autoplay on click to work on mobile */}
          <WistiaEmbed
            {...props}
            id={embedID}
            initialState={isVisible ? 'playing' : 'paused'}
            options={{
              preload: 'metadata', // only load metadata
              fullscreenOnRotateToLandscape: true, // mobile fullscreen trigger
              videoFoam: true,
              endVideoBehavior: 'loop',
            }}
          />
        </AspectRatio>
      </div>
    </div>
  </Portal>
)

VideoLightbox.propTypes = {
  embedID: PropTypes.string,
  isVisible: PropTypes.bool,
  onHide: PropTypes.func,
  className: PropTypes.string,
  onTop: PropTypes.bool,
}

export default forwardRef(VideoLightbox)
