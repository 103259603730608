import { useEffect } from 'react'

import useUIContext from 'context/ui'

const VerticalScroll = ({ children }) => {
  const requestVerticalScroll = useUIContext((s) => s.requestVerticalScroll)
  const releaseVerticalScroll = useUIContext((s) => s.releaseVerticalScroll)

  useEffect(() => {
    requestVerticalScroll()
    return () => {
      releaseVerticalScroll()
    }
  }, [requestVerticalScroll, releaseVerticalScroll])

  return children
}

export default VerticalScroll
