import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import Page from './Page'

import s from './PartnersSection.module.scss'
const cn = classNames.bind(s)

const Grid = ({ pages }) => (
  <div className={cn('logos')}>
    {pages?.map((page, i) => (
      <Page key={i} logos={page} />
    ))}
  </div>
)

Grid.propTypes = {
  pages: PropTypes.array.isRequired,
}

export default Grid
