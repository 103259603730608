import { graphql } from 'gatsby'

// if link type is unknown
export const PrismicLinkFrag = graphql`
  fragment PrismicLinkFrag on PRISMIC__Linkable {
    _linkType

    ... on PRISMIC__Document {
      _meta {
        uid
        id
        type
        tags
      }
    }

    ... on PRISMIC__ExternalLink {
      url
    }
  }
`

export const PrismicDocLinkFrag = graphql`
  fragment PrismicDocLinkFrag on PRISMIC__Document {
    _meta {
      uid
      id
      type
    }
  }
`

export const PrismicExtLinkFrag = graphql`
  fragment PrismicExtLinkFrag on PRISMIC__ExternalLink {
    url
  }
`
