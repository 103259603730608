import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { play, pause } from 'lib/videoControls'
import classNames from 'classnames/bind'

import Image from 'components/ui/Image'
import Video from 'components/ui/Video'

import s from './CategoriesSection.module.scss'
const cn = classNames.bind(s)

const Thumbnail = ({ media, isActive }) => {
  const hasVideo = media.webm || media.mp4
  const videoRef = useRef(null)

  useEffect(() => {
    if (!videoRef.current || !hasVideo) return
    isActive ? play(videoRef.current) : pause(videoRef.current)

    return () => {
      pause(videoRef.current)
    }
  }, [isActive])

  return (
    <div className={cn(s.thumbnail, isActive && s.isActive)}>
      {media.portraitImage && (
        <div className={cn(s.coverImage, s.portrait)}>
          <Image src={media.portraitImage} alt={media.portraitImageAlt || ''} />
        </div>
      )}
      {!hasVideo && media.landscapeImage && (
        <div className={cn(s.coverImage, s.landscape)}>
          <Image src={media.landscapeImage} alt={media.landscapeImage.alt} />
        </div>
      )}
      {hasVideo && (
        <Video className={s.video} mp4={media.mp4 || null} webm={media.webm || null} ref={videoRef} autoplay={false} />
      )}
    </div>
  )
}

export default Thumbnail

Thumbnail.propTypes = {
  media: PropTypes.objectOf({
    portraitImage: PropTypes.string,
    portraitImageAlt: PropTypes.string,
    landscapeImage: PropTypes.string,
    landscapeVideoWEBM: PropTypes.object,
    landscapeVideoMP4: PropTypes.object,
  }),
  isActive: PropTypes.bool,
  isInView: PropTypes.bool,
}
