import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import Logo from './Logo'

import s from './PartnersSection.module.scss'
const cn = classNames.bind(s)

const Page = ({ logos }) => {
  return (
    <div className={cn('page')}>
      {logos?.map((item, i) => (
        <Logo
          key={i}
          r={item.logo.dimensions.width / item.logo.dimensions.height}
          src={item.logo.url}
          alt={item.logo.alt || 'logo'}
        />
      ))}
    </div>
  )
}

Page.propTypes = {
  logos: PropTypes.array.isRequired,
}

export default Page
