import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import ViewportEnter from 'components/motion/ViewportEnter'
import { Headline3 } from 'components/ui/Typography'

import s from './CategoriesSection.module.scss'
const cn = classNames.bind(s)

const Category = ({ title, index, activeIndex, isDraggable, onActive }) => {
  const onViewportEnter = () => {
    isDraggable && onActive(index)
  }

  const onMouseEnter = () => {
    !isDraggable && onActive(index)
  }

  return (
    <div className={cn('category', { isActive: activeIndex === index })} onMouseEnter={onMouseEnter}>
      <ViewportEnter onEnter={onViewportEnter} once={false} threshold={1}>
        <div>
          <Headline3 className={cn('title')} data-content={title}>
            <span className={cn('label')}>{title}</span>
          </Headline3>
        </div>
      </ViewportEnter>
    </div>
  )
}

Category.propTypes = {
  title: PropTypes.string,
  index: PropTypes.number,
  activeIndex: PropTypes.number,
  isDraggable: PropTypes.bool,
  onActive: PropTypes.func,
}

export default Category
