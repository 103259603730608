import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import Image from 'components/ui/Image'

import s from './PartnersSection.module.scss'
const cn = classNames.bind(s)

const Logo = ({ r, src, alt }) => {
  return (
    <div className={cn('logo')}>
      <Image src={src} alt={alt} />
    </div>
  )
}

Logo.propTypes = {
  r: PropTypes.number,
  src: PropTypes.string,
  alt: PropTypes.string,
}

export default Logo
