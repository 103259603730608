import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useWindowSize } from '@react-hook/window-size'
import TransitionLink from 'gatsby-plugin-transition-link'
import classNames from 'classnames/bind'
import { motion } from 'framer-motion'

import Category from './Category'

import s from './CategoriesSection.module.scss'
const cn = classNames.bind(s)

const _ = {
  exitLength: 1.5,
  exitDelay: 0, // before unmounting
  entryLength: 0, // duration of "entering" class before switching to "entered"
  entryDelay: 1.5, // before mounting new page
}

const CategoriesContent = ({ categories, isDraggable, activeIndex, onActive }) => {
  const [viewportWidth] = useWindowSize({ initialWidth: 0 })
  const constraintRef = useRef()
  const [leftConstraint, setLeftConstraint] = useState(0)

  useEffect(() => {
    constraintRef.current && setLeftConstraint(-constraintRef.current.clientWidth + viewportWidth)
  }, [constraintRef, viewportWidth])

  return (
    <motion.div
      className={cn('categoriesContent')}
      drag="x"
      dragElastic={isDraggable ? 0.2 : 0}
      dragConstraints={{
        left: isDraggable ? leftConstraint : 0,
        right: 0,
      }}
    >
      {categories &&
        categories.map((c, i) => (
          <TransitionLink
            key={i}
            className={cn('categoryLink')}
            aria-label="category"
            to={`/work/?${c.uid}`}
            exit={{
              delay: _.exitDelay,
              length: _.exitLength,
              state: { to: 'WORK_PAGE' },
            }}
            entry={{
              delay: _.entryDelay,
              length: _.entryLength,
              // state: { from: 'HOME_PAGE' },
            }}
          >
            <Category
              title={c.title}
              index={i}
              activeIndex={activeIndex}
              isDraggable={isDraggable}
              onActive={onActive}
            />
          </TransitionLink>
        ))}
      <span className={cn('constraint')} ref={constraintRef}></span>
    </motion.div>
  )
}

CategoriesContent.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      uid: PropTypes.string,
    }),
  ),
  isDraggable: PropTypes.bool,
  activeIndex: PropTypes.number,
  onActive: PropTypes.func,
}

export default CategoriesContent
