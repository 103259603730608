import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import TransitionLink, { TransitionState } from 'gatsby-plugin-transition-link'
import classNames from 'classnames/bind'

import { PrismicLink } from 'prismic'

import scrollTo from 'lib/scrollTo'
import { BodyCopy } from 'components/ui/Typography'
import Button from 'components/ui/Button'
import { Container, Section } from 'components/layout'
import WorkThumbnail from 'components/ui/WorkThumbnail'

import s from './WorkSection.module.scss'
const cn = classNames.bind(s)

const _ = {
  exitLength: 1.5,
  exitDelay: 0, // before unmounting
  entryLength: 2, // duration of "entering" class before switching to "entered"
  entryDelay: 1.5, // before mounting new page
}

const ShowAllWorkLink = (props, delay) => (
  <TransitionLink
    to="/work/"
    exit={{
      delay: _.exitDelay,
      length: _.exitLength,
      state: { to: 'WORK_PAGE' },
    }}
    entry={{
      delay: _.entryDelay,
      length: _.entryLength,
      // state: { from: 'HOME_PAGE' },
    }}
    {...props}
  />
)

const ProjectLink = (props, delay) => (
  <TransitionLink
    to="/work/"
    exit={{
      delay: _.exitDelay,
      length: _.exitLength,
      state: { to: 'PROJECT_PAGE' },
    }}
    entry={{
      delay: _.entryDelay,
      length: _.entryLength,
      // state: { from: 'HOME_PAGE' },
    }}
    aria-label="project"
    {...props}
  />
)

const WorkSection = ({ title, projects }) => {
  const element = useRef()
  const [inView, setInView] = useState(false)

  return (
    <Section ref={element} onEnter={() => setInView(true)} className={s.section}>
      <TransitionState>
        {({ mount, transitionStatus, current } = {}) => {
          return (
            <Container>
              <nav className={cn('navigation', { transitionOut: transitionStatus === 'exiting' })}>
                {title && <BodyCopy as="h2">{title[0].text}</BodyCopy>}
                <div className={cn('btn')}>
                  <Button to={null}>
                    <ShowAllWorkLink
                      onClick={() => {
                        scrollTo(element.current)
                      }}
                    >
                      <BodyCopy>View all</BodyCopy>
                    </ShowAllWorkLink>
                  </Button>
                </div>
              </nav>
              <div className={cn(s.thumbnails)}>
                {projects?.map((project, i) => (
                  <ProjectLink
                    key={i}
                    className={s.thumbnail}
                    to={PrismicLink.url(project?.case_study)}
                    onClick={() => {
                      scrollTo(element.current)
                    }}
                  >
                    <WorkThumbnail
                      portraitImage={project?.case_study?.portrait_image?.url || null}
                      portraitVideoMP4={project?.case_study?.portrait_video_mp4 || null}
                      portraitVideoWEBM={project?.case_study?.portrait_video_webm || null}
                      landscapeImage={project?.case_study?.landscape_image?.url || null}
                      alt={project?.case_study?.portrait_image?.alt || project?.title}
                      view={{
                        inView: inView,
                        transitionStatus: transitionStatus,
                        transitioningTo: current.state.to || '',
                      }}
                      delay={i * 0.1 + 's'}
                    />
                  </ProjectLink>
                ))}
              </div>
            </Container>
          )
        }}
      </TransitionState>
    </Section>
  )
}

WorkSection.propTypes = {
  title: PropTypes.any,
  projects: PropTypes.array,
}

export default WorkSection
