import React from 'react'
import Layout from 'layouts'

import SEO from 'components/seo'

import Spacer from 'components/ui/Spacer'
import ThemeWaypoint from 'components/ui/ThemeWaypoint'
import HeroSection from './components/HeroSection'
import BlogSection from 'components/ui/BlogSection'
import InfoSection from 'components/ui/InfoSection'
import CategoriesSection from './components/CategoriesSection'
import WorkSection from './components/WorkSection'
import InteractiveCardsSection from './components/InteractiveCardsSection'
import PartnersSection from './components/PartnersSection'
import VerticalScroll from 'components/layout/VerticalScroll'

const HomePage = ({ data: { prismic }, ...props }) => {
  const { page } = prismic
  const metaTitle = page?.page_meta_title
  const metaDescription = page?.page_meta_description
  const metaThumbnail = page?.page_meta_thumbnail

  const heroData = {
    title: page?.hero_title || '',
    videoTrigger: page?.hero_video_trigger || '',
    videoEmbedID: page?.hero_video_embed_id || '',
  }
  const workSectionData = page?.body?.find((i) => i.type === 'worksection') || {}
  const infoSectionsData = page?.body?.filter((i) => i.type === 'info_section') || {}
  const categoriesSectionData = page?.body?.find((i) => i.type === 'categories_section') || {}
  const interactiveCardsSectionData = page?.body?.find((i) => i.type === 'interactive_cards_section') || {}
  const partnersSectionData = page?.body?.find((i) => i.type === 'partners_section') || {}
  const blogSectionData = page?.body?.find((i) => i.type === 'blog_section') || {}

  return (
    <>
      <Layout hasFooterThemeWaypoint={false}>
        <SEO title={metaTitle} description={metaDescription} thumbnail={metaThumbnail} />
        <VerticalScroll>
          <ThemeWaypoint after="dark" />
          <HeroSection
            title={heroData?.title[0].text}
            videoTrigger={heroData?.videoTrigger}
            videoEmbedID={heroData?.videoEmbedID || null}
          />

          <WorkSection title={workSectionData.primary?.section_title} projects={workSectionData.fields} />
          <Spacer initial="6rem" medium="13.8vw" />
          <InfoSection data={infoSectionsData[0] || {}} />
          <Spacer initial="6rem" medium="13.8vw" />
          <CategoriesSection data={categoriesSectionData?.fields} />
          <Spacer initial="6rem" medium="13.8vw" />
          <InfoSection data={infoSectionsData[1] || {}} />
          <Spacer initial="6rem" medium="13.8vw" />
          <InteractiveCardsSection
            title={interactiveCardsSectionData?.primary.section_title}
            link={interactiveCardsSectionData?.primary.link}
            linkText={interactiveCardsSectionData?.primary.link_text}
            images={interactiveCardsSectionData?.fields}
          />
          <Spacer initial="6rem" medium="13.8vw" />
          <PartnersSection title={partnersSectionData?.primary.section_title} logos={partnersSectionData?.fields} />
          <Spacer initial="6rem" medium="13.8vw" />
          <BlogSection
            title={blogSectionData.primary?.section_title}
            posts={blogSectionData.fields}
            viewAllLabel="View blog"
            renderShapes
            useCustomPageTransition
          />
          <Spacer initial="6rem" medium="0" />
        </VerticalScroll>
      </Layout>
    </>
  )
}

export default HomePage
