import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import classNames from 'classnames/bind'

import Image from 'components/ui/Image'

import s from './InteractiveCardsSection.module.scss'
const cn = classNames.bind(s)

const ratioClass = (r) => {
  if (r < 1) return 'portrait'
  if (r > 1) return 'landscape'
  return 'square'
}

const variants = {
  hide: {
    x: 0,
    y: 0,
    transition: {
      duration: 0.45,
    },
  },
}

const Card = ({ image, constraintRef, index, total, maxIndex, onCardDragged, show, alt }) => {
  const [zIndex, setZIndex] = useState(total - index)
  const [isDragging, setIsDragging] = useState(false)
  const r = image?.imageSharp?.childImageSharp?.fluid.aspectRatio || 1

  const onStart = () => {
    setIsDragging(true)
    setZIndex(maxIndex)
    onCardDragged(maxIndex + 1)
  }

  const onEnd = () => {
    setIsDragging(false)
  }

  useEffect(() => {
    !show && setZIndex(total - index)
  }, [show])

  return (
    <motion.div
      className={cn('cardWrapper', { isDragging }, ratioClass(r))}
      style={{ zIndex }}
      drag
      dragConstraints={constraintRef}
      onPointerDown={onStart}
      onDragStart={onStart}
      onPointerUp={onEnd}
      onDragEnd={onEnd}
      animate={!show && 'hide'}
      variants={variants}
    >
      <div className={cn('card')}>
        <div className={cn('inner')}>
          {image && (
            // support both imageSharp and SVGs
            <Image
              alt={alt || image.image.alt}
              src={image.imageSharp.childImageSharp ? image.imageSharp : image.image.url}
              className={cn('image')}
            />
          )}
        </div>
      </div>
    </motion.div>
  )
}

Card.propTypes = {
  image: PropTypes.object,
  alt: PropTypes.string,
  constraintRef: PropTypes.object,
  index: PropTypes.number,
  total: PropTypes.number,
  show: PropTypes.bool,
  maxIndex: PropTypes.number,
  onCardDragged: PropTypes.func,
}

export default memo(Card)
