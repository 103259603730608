import React, { useEffect, useMemo, useRef } from 'react'
import propTypes from 'prop-types'
import classNames from 'classnames/bind'
import { ResizeObserver } from '@juggle/resize-observer'

import useUIContext from 'context/ui'

import { CURSOR_ICONS } from 'components/ui/Cursor'

import s from './HeroSection.module.scss'
const cn = classNames.bind(s)

const HeroTitle = ({ title, videoTrigger, onClick }) => {
  const heroTitleRef = useRef(null)
  const wordsRef = useRef(null)

  const content = useMemo(() => {
    const titleSplit = title.split(' ')
    return [...titleSplit, videoTrigger]
  }, [title, videoTrigger])

  const setCursorIcon = useUIContext((s) => s.setCursorIcon)
  const isFontLoaded = useUIContext((s) => s.isFontLoaded)

  const onResize = () => {
    if (!heroTitleRef.current) return
    if (!wordsRef.current || !wordsRef.current.length) wordsRef.current = Array.from(heroTitleRef.current.children)
    let count = 0

    wordsRef.current.forEach((word, index) => {
      const offsetTop = word.offsetTop
      if (index > 0 && offsetTop > wordsRef.current[index - 1].offsetTop) count++
      word.style.setProperty(`--d`, `${count * 0.2 + 0.35}s`)
    })
  }

  useEffect(() => {
    if (!heroTitleRef.current) return
    const ro = new ResizeObserver((entries, observer) => onResize())
    ro.observe(heroTitleRef.current)

    return () => {
      ro.disconnect()
    }
  }, [isFontLoaded])

  const onVideoTriggerHover = () => {
    setCursorIcon(CURSOR_ICONS.VIDEO_CONTROLS_PLAY)
  }

  const onVideoTriggerIdle = () => {
    setCursorIcon(null)
  }

  return (
    <div ref={heroTitleRef}>
      {content.map((i, index) => {
        const isVideoTrigger = index === content.length - 1
        return (
          <span
            key={index}
            className={cn('word', { isVideoTrigger })}
            onMouseEnter={isVideoTrigger ? onVideoTriggerHover : undefined}
            onMouseLeave={isVideoTrigger ? onVideoTriggerIdle : undefined}
            onClick={isVideoTrigger ? onClick : undefined}
          >
            <span className={cn('wordInner')}>
              {i}
              {!isVideoTrigger && <>&nbsp;</>}
            </span>
          </span>
        )
      })}
    </div>
  )
}

HeroTitle.propTypes = {
  title: propTypes.string.isRequired,
  videoTrigger: propTypes.string,
  onClick: propTypes.func,
}

export default HeroTitle
