import React, { useState, useRef } from 'react'
import { linkResolver } from 'prismic/link-resolver'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import { Section, Container } from 'components/layout'
import ViewportEnter from 'components/motion/ViewportEnter'
import { BodyCopy } from 'components/ui/Typography'
import Button from 'components/ui/Button'
import Card from './Card'

import InteractiveCardsLogo from 'assets/svg/interactive-cards-logo.inline.svg'

import s from './InteractiveCardsSection.module.scss'
const cn = classNames.bind(s)

const _ = {
  maxLength: 9,
}

const InteractiveCardsSection = ({ title, link, linkText, images }) => {
  const [inView, setInView] = useState(false)
  const [showCards, setShowCards] = useState(false)
  const [maxIndex, setMaxIndex] = useState(_.maxLength)
  const constraintRef = useRef()

  return (
    <Section
      className={cn('wrapper', { inView })}
      onEnter={() => setShowCards(true)}
      onExit={() => setShowCards(false)}
      threshold={0}
      once={false}
    >
      <Container>
        <div className={cn('constraints')} ref={constraintRef} />
        <nav className={cn('navigation')}>
          {title && <BodyCopy as="h2">{title[0].text}</BodyCopy>}
          <Button to={linkResolver(link._meta)}>
            <BodyCopy>{linkText}</BodyCopy>
          </Button>
        </nav>
        <ViewportEnter onEnter={() => setInView(true)} threshold={0.5}>
          <div className={cn('logo')}>
            <div className={cn('mover')}>
              <InteractiveCardsLogo />
            </div>
            <div className={cn('cards')}>
              {constraintRef.current &&
                images
                  ?.slice(0, _.maxLength)
                  .map((image, i) => (
                    <Card
                      drag
                      image={image}
                      alt={image.image.alt || 'card'}
                      key={i}
                      constraintRef={constraintRef}
                      index={i}
                      total={images.length}
                      show={showCards}
                      onCardDragged={setMaxIndex}
                      maxIndex={maxIndex}
                    />
                  ))}
            </div>
          </div>
        </ViewportEnter>
      </Container>
    </Section>
  )
}

InteractiveCardsSection.propTypes = {
  title: PropTypes.array,
  link: PropTypes.object,
  linkText: PropTypes.string,
  images: PropTypes.array,
}

export default InteractiveCardsSection
