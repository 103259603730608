const swallowError = () => ({})

export const play = (video) => {
  if (!video || typeof video.play !== 'function') return
  const promise = video.play()
  if (promise) promise.catch(swallowError)
}

export const pause = (video) => {
  if (!video || typeof video.pause !== 'function') return
  video.pause()
}
