import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useWindowSize } from '@react-hook/window-size'
import classNames from 'classnames/bind'
import { Section, Container } from 'components/layout'

import { BodyCopy } from 'components/ui/Typography'
import { Row, Column12 } from 'components/ui/Grid'

import Grid from './Grid'
import Carousel from './Carousel'

import { desktop } from 'styles/config/_breakpoints.scss'

import s from './PartnersSection.module.scss'
const cn = classNames.bind(s)

const _ = {
  logosPerPage: 4,
}

const splitIntoChunks = (array, size) =>
  array.reduce((chunks, item, i) => {
    if (i % size === 0) {
      chunks.push([item])
    } else {
      chunks[chunks.length - 1].push(item)
    }
    return chunks
  }, [])

const PartnersSection = ({ title, logos }) => {
  const [viewportWidth] = useWindowSize({ initialWidth: 0 })
  const [pages, setPages] = useState(null)
  const [isCarousel, setIsCarousel] = useState(true)

  useEffect(() => setIsCarousel(viewportWidth <= parseInt(desktop)), [viewportWidth])
  useEffect(() => logos && setPages(splitIntoChunks(logos, _.logosPerPage)), [logos])

  if (!pages) return null
  return (
    <Section className={cn('wrapper')}>
      <Container>
        <Row>
          <Column12 initial={12} desktop={10} center>
            <div className={cn('headline')}>{title && <BodyCopy as="h2">{title[0].text}</BodyCopy>}</div>
            {isCarousel ? <Carousel pages={pages} /> : <Grid pages={pages} />}
          </Column12>
        </Row>
      </Container>
    </Section>
  )
}

PartnersSection.propTypes = {
  title: PropTypes.array,
  logos: PropTypes.array,
}

export default PartnersSection
